import $ from "jquery";

// Connect wallet

export const connectWalletSection = $(".connect-wallet");
export const connectWalletBtn = $(".btn-connect-wallet");

// Wallet connected

// Place bet
export const walletConnectedSection = $(".wallet-connected");
export const placeBetForm = $(".form-place-bet");
export const placeBetBtn = placeBetForm.find("button");
export const placeBetRouletteNumberInput = placeBetForm.find("input.roulette-number");
export const placeBetValueInput = placeBetForm.find("input.value");

// Claim reward
export const claimRewardForm = $(".form-claim-reward");
export const claimRewardBtn = claimRewardForm.find("button");
export const claimRewardIndexInput = claimRewardForm.find("input.index");

// All bets table
export const allBetsTable = $("table.table-all-bets");
export const allBetsTableBody = allBetsTable.find("tbody");
export const allBetsTemplate = $("template.template-all-bets");

// User winnings table
export const userWinningsTable = $("table.table-user-winnings");
export const userWinningsTableBody = userWinningsTable.find("tbody");
export const userWinningsTemplate = $("template.template-user-winnings");
