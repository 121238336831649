import $ from 'jquery'
import { BigNumber, Contract, ethers, Signer, providers, utils, Event, errors } from 'ethers';
import CONTRACT_ABI from './ContractAbi.json';
import * as html from "./Web3HtmlElements";


interface Bet {
    user: string;
    value: BigNumber;
    blockNumber: number;
    rouletteNumber: number;
    claimed: boolean;
}

interface Winning {
    reward: BigNumber;
    betIndex: BigNumber;
    bet: Bet;
}

const CONTRACT_ADDRESS = "0xe76806689907c9D5417696ae5f58FBc9e793230A";
const ETHERSCAN_BASE_URL = "https://goerli.etherscan.io/";

export class Web3Handler {
    private provider: providers.Web3Provider;
    private signer: Signer;
    private contract: Contract;
    private contractInterface: utils.Interface;
    private startBlockNumber: number;

    run() {
        this.provider = new providers.Web3Provider(window.ethereum);
        this.contractInterface = new utils.Interface(CONTRACT_ABI);
        this.connectWallet();
        this.registerEventHandlers();
    }

    async connectWallet() {
        await this.provider.send("eth_requestAccounts", []).then(async () => {
            this.signer = this.provider.getSigner();
            const Roulette = new ethers.Contract(
                CONTRACT_ADDRESS,
                this.contractInterface,
                this.signer
            );
            this.contract = Roulette.connect(this.signer);
            this.onWalletConnected();
        });
    }

    async onWalletConnected() {
        this.startBlockNumber = await this.provider.getBlockNumber();
        this.updateMinMaxBet();
        this.renderUserWinnings();
        this.registerBlockchainEventHandlers();
        this.getPastBets();

        html.connectWalletSection.hide();
        html.walletConnectedSection.show();
    }

    async updateMinMaxBet() {
        const [minBet, maxBet] = await this.getMinMaxBet();
        const valueInput = $(".form-place-bet input.value")
        const placeholder = valueInput.attr("placeholder");
        valueInput.attr("min", minBet);
        valueInput.attr("max", maxBet);
        valueInput.attr("step", minBet);
        valueInput.attr("placeholder", `${placeholder} (${minBet}-${maxBet})`);
    }

    async getMinMaxBet(): Promise<[string, string]> {
        return Promise.all([this.contract.minimumBet(), this.contract.maximumBet()])
            .then(async ([minBet, maxBet]) =>
                [utils.formatEther(minBet), utils.formatEther(maxBet)]
            );
    }

    async placeBet(rouletteNumber: number, value: BigNumber) {
        return this.contract.placeBet(rouletteNumber, {value: value}).catch((error: any) => {
            // console.log(error);
            if (error.code === errors.UNPREDICTABLE_GAS_LIMIT) {
                alert(error.reason);
            }
        });
    }

    async claimReward(index: BigNumber) {
        return this.contract.claimRewardByIndex(index).catch((error: any) => {
            // console.log(error);
            if (error.code === errors.UNPREDICTABLE_GAS_LIMIT) {
                alert(error.reason);
            }
        });
    }

    async getPastBets() {
        const betPlacedFilter = this.contract.filters.BetPlaced();
        let events = await this.contract.queryFilter(betPlacedFilter);
        for (const event of events) {
            await this.addBet(event);
        }
    }

    async addBet(event: Event) {
        const transaction = await this.provider.getTransaction(event.transactionHash);
        let decodedData = this.contractInterface.parseTransaction(transaction);
        const newBet: Bet = {
            user: transaction.from,
            value: transaction.value,
            blockNumber: transaction.blockNumber,
            rouletteNumber: decodedData.args.rouletteNumber,
            claimed: undefined
        };
        this.renderBet(newBet.user, newBet.blockNumber, newBet.rouletteNumber, newBet.value);
    }

    renderBet(user: string, blockNumber: number, rouletteNumber: number, value: BigNumber) {
        const userShort = `${user.slice(0, 2 + 16)}...`;
        const userUrl = new URL("/address/" + user, ETHERSCAN_BASE_URL).toString();
        const blockUrl = new URL("/block/" + blockNumber, ETHERSCAN_BASE_URL).toString();

        const clone = html.allBetsTemplate.contents().clone();
        const userLink = $("<a>").appendTo(clone.find("td[data-field-name='user']"));
        const blockLink = $("<a>").appendTo(clone.find("td[data-field-name='block']"));
        const rouletteNumberField = clone.find("td[data-field-name='number']");
        const valueField = clone.find("td[data-field-name='value']");

        userLink.text(userShort).attr("href", userUrl).attr("target", "_blank");
        blockLink.text(blockNumber).attr("href", blockUrl).attr("target", "_blank");
        rouletteNumberField.text(rouletteNumber);
        valueField.text(utils.formatEther(value) + " ETH");

        clone.prependTo(html.allBetsTableBody);
    }

    async renderUserWinnings() {
        this.getWinnings().then((winnings) => {
            html.userWinningsTableBody.remove("tr");
            for (const winning of winnings) {
                this.renderUserWinning(winning.reward, winning.betIndex);
            }
        });
    }

    renderUserWinning(reward: BigNumber, index: BigNumber) {
        const clone = html.userWinningsTemplate.contents().clone();
        clone.find("td[data-field-name='index']").text(index.toString());
        clone.find("td[data-field-name='value']").text(utils.formatEther(reward) + " ETH");
        clone.appendTo(html.userWinningsTableBody);
    }

    async getWinnings(): Promise<Winning[]> {
        return this.contract.userWinningBets(await this.signer.getAddress());
    }

    async registerBlockchainEventHandlers() {
        this.contract.on("BetPlaced", this.onBetPlaced.bind(this));
        this.contract.on("RewardClaimed", this.onRewardClaimed.bind(this));
        this.provider.on("block", this.onBlock.bind(this));
    }

    private onBetPlaced(...args: any[]) {
        console.log("Bet placed:", args);
        const event = args[args.length - 1];
        if(event.blockNumber <= this.startBlockNumber) return;
    }

    private onRewardClaimed(...args: any[]) {
        const event = args[args.length - 1];
        if(event.blockNumber <= this.startBlockNumber) return;

        this.renderUserWinnings();
    }

    private onBlock(_blockNumber: BigNumber) {
        this.renderUserWinnings();
    }

    async registerEventHandlers() {
        html.connectWalletBtn.on("click", this.onConnectWalletBtnClicked.bind(this));
        html.placeBetBtn.on("click", this.onPlaceBetBtnClicked.bind(this));
        html.claimRewardBtn.on("click", this.onClaimRewardButtonClicked.bind(this));
    }

    onConnectWalletBtnClicked(_e: JQuery.ClickEvent) {
        this.connectWallet();
    }

    onPlaceBetBtnClicked(_e: JQuery.ClickEvent) {
        const rouletteNumber: number = Number(html.placeBetRouletteNumberInput.val());
        const etherValue: string = html.placeBetValueInput.val().toString();
        this.placeBet(rouletteNumber, utils.parseEther(etherValue));
    }

    onClaimRewardButtonClicked(_e: JQuery.ClickEvent) {
        const index: string = html.claimRewardIndexInput.val().toString();
        this.claimReward(BigNumber.from(index));
    }
}
