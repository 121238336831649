import $ from 'jquery';
import jQuery from 'jquery';
import { Web3Handler } from './Web3Handler';

declare global {
    interface Window {
        ethereum: any;
    }
}

main();

function main() {
    if (typeof window.ethereum !== "undefined") {
        new Web3Handler().run();
    }

    init();
}

function init() {
    registerEventHandlers();
}

async function registerEventHandlers() {
    $("button").on("click", onButtonClicked);
}

function onButtonClicked(e: JQuery.ClickEvent) {
    $(e.currentTarget).trigger("blur");
}


jQuery.fn.extend({
    hide: function() {
        $(this).addClass("hidden");
    },
    show: function() {
        $(this).removeClass("hidden");
    }
});
